import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import Table from "../../../components/table";
import Layout from "../../../components/layout";
import Lagnavn from "../../../components/lagnavn";

function App({ location }) {

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "place",
      },
      {
        Header: "Navn",
        accessor: "logo.url",
        Cell: (logo) => (
          <Lagnavn logo={logo.cell}/>
        ),
      },

      {
        Header: "Navn",
        accessor: "name"
      },
      {
        Header: "Poeng",
        accessor: "points"
      },
      {
        Header: "Vunnet",
        accessor: "won"
      },
      {
        Header: "Tap",
        accessor: "lost"
      }
    ],
    []
  );

  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      const result = await axios("https://api.nifs.no/tournaments/44/stages/681726/table/");
      setData(result.data.teams);
    })();
  }, []);

  return (
    <Layout location={location} className="App">
      <Table type="table" hidden="name" title="Tabelloversikt" columns={columns} data={data} />
    </Layout>
  );
}

export default App;
